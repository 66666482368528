<template>
  <section>
    <div class="moveUpOnPrinter">
      <v-container>
        <div v-if="transaction.uuid !== null && isLoading === false">
          <div class="friendly-2 mt-6 pb-3">Thank you, {{ this.transaction.customer.firstName }}</div>
          <v-row>
            <v-col>
              <div class="mt-5">
                Thank you for your order with Sea2Sea Scuba! We are thrilled that you chose us to fulfill your Scuba Diving needs. We appreciate your trust in our brand and we are committed to delivering the best possible customer experience.
                <br>
                <br>
                Your order has been received and is being processed by our team. We will provide you with a confirmation email shortly <span class="caption">({{ this.transaction.customer.email }})</span> and if warranted a team member will reach out regarding your order (usually over the phone).
                <br>
                <br>
                In the meantime, please do not hesitate to contact us if you have any questions or concerns about your order. We are always happy to help.
                <br>
                <br>
                Again, thank you for choosing Sea2Sea Scuba. We look forward to serving you and hope to exceed your expectations.
                <br>
                <br>
                Best regards,
                <br>
                <span class="font-weight-bold">Sea2Sea Scuba</span>
              </div>
              <div class="showOnPrinterOnly">
                <ul>
                  <li>Order ID: <br><span class="lastSixUuid caption" :data-end="transaction.uuid.substr(-6)">{{ transaction.uuid.substr(0, transaction.uuid.length - 6) }}</span></li>
                  <li>Date: <br>{{ $moment(transaction.createdAt).format('lll') }}</li>
                  <li>Name: <br>{{ transaction.customer.fullName }}</li>
                  <li>Email: <br>{{ transaction.customer.email }}</li>
                  <li>Phone: <br>{{ transaction.customer.phoneNumber }}</li>
                </ul>
              </div>
            </v-col>
            <v-col sm="4" class="sticky hidden-xs-only">
              <v-card>
                <v-card-title>
                  Your order
                </v-card-title>
                <v-card-subtitle>
                  <div>
                    {{ this.transaction.customer.email }}
                  </div>
                  <div>
                    <span class="font-weight-bold text-uppercase">ORDER #{{ transaction.uuid.substr(-6) }}</span>
                  </div>
                </v-card-subtitle>
                <v-card-text>
                  <v-list>
                    <v-list-item v-for="(product, index) in this.transaction.products" :key="index">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ product.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ product.quantity }} x ${{ product.cents / 100 }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-row>
                    <v-col align="right">
                      <span class="friendly-1 font-weight-bold">
                        Total ${{  this.transaction.cents / 100 }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn color="primary" block @click="doPrintPage()" class="hideOnPrinter">
                        <font-awesome-icon :icon="['fas', 'print']"/>
                        <span class="ml-2">Print my order</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <div class="showOnPrinterOnly">
                    <span>Contact us: </span>
                    <br>
                    <br>
                    <span class="font-weight-bold">
                      <font-awesome-icon class="mr-1" :icon="['fas', 'phone']"/>
                      (480) 448-5636
                    </span>
                  </div>
                  <div class="showOnPrinterOnly mt-2" style="position: relative; right:0; top: 0; width:100%;">
                    <hr class="mt-2">
                    <div class="text-center mb-2">
                      <span class="friendly-1 mb-4">Scan to view online</span>
                      <br>
                      <img class="mt-2" :src="currentPageQR">
                    </div>
                    <hr>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div class="hidden-sm-and-up hideOnPrinter">
            <v-card>
              <v-card-title>
                Your order
              </v-card-title>
              <v-card-subtitle>
                <div>
                  {{ this.transaction.customer.email }}
                </div>
              </v-card-subtitle>
              <v-card-text>
                <v-list>
                  <v-list-item v-for="(product, index) in this.transaction.products" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ product.quantity }} x ${{ product.cents / 100 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-row>
                  <v-col align="right">
                      <span class="friendly-1 font-weight-bold">
                        Total ${{  this.transaction.cents / 100 }}
                      </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="primary" block @click="doPrintPage()" class="hideOnPrinter">
                      <font-awesome-icon :icon="['fas', 'print']"/>
                      <span class="ml-2">Print my order</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="text-center mt-16" v-else>
          <v-progress-circular
            :size="100"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'success',
  data: () => ({
    isLoading: true,
    currentPageQR: String(),
    transaction: {
      uuid: null,
      paymentStatus: null,
      customer: {
        firstName: null
      }
    }
  }),
  methods: {
    doReadQuery () {
      const query = this.$route.query

      if ('clearCart' in query) {
        this.$store.dispatch('doResetProductGroupsCart').then(_ => {
          if ('uuid' in query) {
            this.$router.replace({ query: { uuid: query.uuid } })
          } else {
            this.$router.replace({ name: 'home' })
          }
        })
      }

      if ('uuid' in query) {
        this.transaction.uuid = query.uuid

        this.$services.transaction.doReadPublicly(this.transaction.uuid).then(transaction => {
          this.transaction = transaction
          this.isLoading = false
          this.loadPrintMedia()
        }).catch(_ => {
          this.$root.$emit('showError', 'An error has occurred with retrieving your order, please contact us. Do not place the order again!')
          this.$router.replace({ name: 'home' })
        })
      } else {
        this.$router.replace({ name: 'home' })
      }
    },
    toDataURL (src, callback) {
      var img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = function () {
        var canvas = document.createElement('CANVAS')
        var ctx = canvas.getContext('2d')
        var dataURL
        canvas.height = this.naturalHeight
        canvas.width = this.naturalWidth
        ctx.drawImage(this, 0, 0)
        dataURL = canvas.toDataURL('image/jpeg')
        callback(dataURL)
      }
      img.src = src
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
        img.src = src
      }
    },
    loadPrintMedia () {
      this.toDataURL(`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${window.location}`, (dataUrl) => {
        this.currentPageQR = dataUrl
      })
    },
    doPrintPage () {
      window.print()
    }
  },
  mounted () {
    this.doReadQuery()
  }
}
</script>

<style scoped>
@media print {
  .moveUpOnPrinter {
    margin-top: -80px;
  }
}

.sticky {
  position: sticky;
  top: 80px;
}

.lastSixUuid::after {
  content: attr(data-end);
  font-weight: bolder;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px !important;
}
</style>
